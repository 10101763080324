<template>
  <v-container fluid grid-list-lg style="overflow: scroll">



    <v-card class="rounded-xl" outlined>
      <v-card-title primary-title>
        <v-layout row wrap align-center class="mb-3">
          <v-flex shrink>
            <v-btn icon outlined @click.stop.prevent="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink class="font-weight-bold black--text">
            Activation
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink class="text-right">
            <SearchBar v-model="search" />
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-card flat class="cardTitle" :color="$style.card.background">
          <v-card-text>
            <v-layout row wrap>
              <v-flex shrink>
                <DateTimePicker :range="true" v-model="dateTime" :initStartDate="getStart" :initEndDate="getEnd"
                  @submit="getList()" :defaultRange="defaultRange" />
              </v-flex>
              <!-- <v-flex class="ml-2" shrink> -->
              <!-- <v-select
                hide-details
                item-text="name"
                :items="items"
                return-object
                v-model="selected"
                solo
              ></v-select> -->
              <!-- </v-flex> -->
              <v-spacer></v-spacer>
              <v-flex shrink v-if="
  !$vuetify.breakpoint.xsOnly
">
                <DateSelectorView :dateTime="dateTime" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <v-data-table class="table-header" hide-default-footer :headers="headers" :items-per-page="serials.length"
          :items="serials" :loading="loading">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left small-td">
                {{ item.createdAt | moment('MM/DD/YYYY LT') }}
              </td>
              <td class="text-left small-td">
                <div>{{ item.serialNumber | simNumber }}</div>
                <div v-if="item.plan">{{ item.plan }}</div>
                <!-- {{ item.serialNumber | simNumber }} -->
              </td>
              <td v-if="item.phoneNumber">{{ item.phoneNumber | USPhoneNumber }}</td>
              <td v-else>
                <v-chip small label>Processing...</v-chip>
              </td>
              <td v-if="item.metadata
  && item.metadata.simInfo
  && item.metadata.simInfo.activation">{{ item.metadata.simInfo.activation }}</td>
              <td v-else>N/A</td>
              <td>
                <v-chip class="text-capitalize" small label dark :color="item.status == 'activated' ? 'green' : 'red'">
                  {{ item.status }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>


    </v-card>
  </v-container>
</template>
<script>
import historyService from '@/modules/History/service.js';
import DateTimePicker from '@/components/DateTimePicker';
import DateSelectorView from '@/components/DateSelectorView.vue';

export default {
  name: 'sim-history',
  props: ['hideHeader', 'hideCreate', 'ProductId', 'defaultRange'],
  data() {
    return {
      activationDialog: false,
      selectedSerial: null,
      edit: false,
      status: 0,
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      )),
      headers: [
        {
          text: 'Date',
          align: 'left',
          value: 'createdAt',
        },
        {
          text: 'SerialNumber',
          align: 'left',
          value: 'serialNumber',
        },
        {
          text: 'Phone Number',
          align: 'left',
          value: 'serialNumber',
        },
        {
          text: 'Activation Type',
          align: 'left',
          value: "item.metadata.simInfo.activation",
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
      start: this.$moment().startOf('day'),
      end: this.$moment().endOf('day'),
      dateTime: {
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      },
      search: null,
      serials: [],
      count: 0,
      loading: false,
    };
  },
  components: {
    DateTimePicker,
    DateSelectorView
  },
  computed: {
    getStart() {
      if (this.defaultRange) return null
      return this.start
    },
    getEnd() {
      if (this.defaultRange) return null
      return this.end
    },
  },
  created() {
    // this.contact = JSON.parse(localStorage.getItem(
    //   `${process.env.VUE_APP_STORAGE_PREFIX}contact`
    // ))
    // this.AssignedContactId = contact.id
    this.getList();

  },
  watch: {
    search() {
      if (this.search.length > 3 || this.search.length === 0) {
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.loading = true;
      console.log('this.search is :', this.search)
      let start = this.dateTime.start;
      let end = this.dateTime.end;
      return historyService
        .getSims({
          start,
          end,
          limit: 500,
          search: this.search,
          orderBy: ['id', true],
          contactId: this.contact.id,
          cellsimPortalHistory: true
        })
        .then(response => {

          console.log('this.search is :', response)
          this.serials = response.data;
          this.count = response.count;
          this.loading = false;
          return response;
        }).catch(error => {
          this.loading = false
          console.log(' this.loading = false', error)
        })
    },
  },
};
</script>
